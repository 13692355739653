@import '../../../../styles/basics';

.bb-video {
  @include bb-standalone-text-spacing();

  :global(.video-player-block) {
    overflow: hidden;
    position: relative;

    :global(.video-player) > div {
      float: none;
      clear: both;
      width: 100%;
      position: relative;
      padding-bottom: 56.25%; /* 16:9 */
      height: 0;
      & > iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }
  }

  :global(.poster) {
    transition: opacity 0.2s ease-in-out;
    opacity: 1;
    height: 100%;
    top: 0;
    width: 100%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 680px;
    position: relative;

    :global(.preview) {
      cursor: pointer;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;

      :global(.preview-img) {
        width: 100%;

        @include media-breakpoint-down(md) {
          height: auto;
          min-height: 160px;
        }
      }

      :global(.preview-header) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        padding: 10px 15px;
        color: $white;
        font-size: 14px;
        background: linear-gradient(
          to bottom,
          rgba(0, 0, 0, 0.5),
          rgba(0, 0, 0, 0.3) 80%,
          rgba(0, 0, 0, 0) 100%
        );

        @include media-breakpoint-up(md) {
          font-size: 18px;
        }

        img {
          width: 30px;
          border-radius: 50%;
          margin-right: 13px;
          transition: opacity 0.15s ease-in-out;
          opacity: 1;

          @include media-breakpoint-up(md) {
            width: 50px;
          }
        }
      }

      :global(.play-button--loading),
      :global(.play-button) {
        position: absolute;
        width: 40px;
        height: auto;
        @include media-breakpoint-up(md) {
          width: 100px;
        }
      }
    }

    &:hover img {
      opacity: 0.85;
    }
  }

  &:global(.playing.loading .play-button) {
    display: none;
  }

  &:global(.playing:not(.loading) .play-button) {
    display: none;
  }
}
