@mixin hero-dec {
  .red-strip {
    position: absolute;
    background-image: linear-gradient(to bottom, $red-800, $red-800);
    mix-blend-mode: multiply;
    top: 0;
    right: 0;
    width: 90px;
    height: 100%;
    @include media-breakpoint-up(md) {
      width: 136px;
    }
  }

  .watermark {
    position: absolute;
    left: auto;
    right: 10px;
    top: 20px;
    width: 65px;
    height: auto;
    @include media-breakpoint-up(md) {
      width: 95px;
      right: 20px;
    }
  }
}
